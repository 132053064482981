<template>
  <div>
    <a href="#" class="btn btn-primary float-end btn-sm" @click.prevent="show_add_item = true"><i class="lni lni-plus me-2"></i>Add New</a>
    <h3>Frequently Asked Questions</h3>
    <loading v-if="loading" />
    <template v-if="! loading">
      <div class="card mb-3" v-for="(item, i) in data" :key="`item-${i}`">
        <div class="card-header">
          <a href="#" class="float-end" @click.prevent="data.splice(i, 1)"><i class="lni lni-trash-can text-danger"></i></a>
          <a href="#" class="float-end text-primary me-2" @click.prevent="data.splice(i, 1); edit_item = item; show_edit_item = true"><i class="lni lni-pencil"></i>Edit</a>
          <h4 class="card-title">{{ item.title }}</h4>
        </div>
        <div class="card-body" v-html="item.content"></div>
      </div>
      <div class="form-group" v-if="data.length > 0">
        <a href="#" class="btn btn-primary" @click.prevent="submit"><i class="lni kni-save me-2"></i>Save</a>
      </div>
    </template>
    <modal :show="show_add_item" @close="show_add_item = false">
      <template slot="header">Add FAQ Item</template>
      <form @submit.prevent="data.push(new_item); show_add_item = false; new_item = {}">
        <div class="form-group">
          <label>Title</label>
          <input type="text" required v-model="new_item.title" class="form-control" placeholder="Title">
        </div>
        <div class="form-group">
          <label>Content</label>
          <textarea required v-model="new_item.content" class="form-control" placeholder="Content"></textarea>
        </div>
        <div class="form-group">
          <button class="btn btn-primary"><i class="lni lni-plus me-2"></i>Add</button>
        </div>
      </form>
    </modal>
    <modal :show="show_edit_item" @close="show_edit_item = false">
      <template slot="header">Edit FAQ Item</template>
      <form @submit.prevent="data.push(edit_item); show_edit_item = false; edit_item = {}">
        <div class="form-group">
          <label>Title</label>
          <input type="text" required v-model="edit_item.title" class="form-control" placeholder="Title">
        </div>
        <div class="form-group">
          <label>Content</label>
          <textarea required v-model="edit_item.content" class="form-control" placeholder="Content"></textarea>
        </div>
        <div class="form-group">
          <button class="btn btn-primary"><i class="lni lni-save me-2"></i>Save</button>
        </div>
      </form>
    </modal>
  </div>
</template>

<script>
export default {
  mounted() {
    this.fetch()
  },

  data() {
    return {
      data: [],
      loading: true,
      show_add_item: false,
      show_edit_item: false,
      new_item: {},
      edit_item: {}
    }
  },

  methods: {
    fetch() {
      this.loading = true

      this.$axios.get("/api/v1/admin/content/faq").then((response) => {
        this.data = response.data.content;
        this.loading = false
      });
    },

    submit() {
      let data = {
        content: this.data
      }

      this.$axios.put('/api/v1/admin/content/faq', data).then(() => {
        this.fetch()
      })
    }
  }
}
</script>